/* eslint-disable no-undef */
import { React } from "react"

// Styles
import "../styles/embed.css"

let CDN_URL, CDN_ENV
if (
  typeof window !== "undefined" &&
  /^(www\.)?xywavhcp.com|us15\.prod\.acquia-sites\.com$/.test(
    window.location.hostname
  )
) {
  CDN_URL = "https://cdn.pullthrough.tools/CommercialSite/js/PFV.js?rev=1.0.5"
  CDN_ENV = "PROD"
} else {
  CDN_URL = "https://cdn.uat.pullthrough.tools/CommercialSite/js/PFV_v2.js"
  CDN_ENV = "UAT"
}

const FindYourDoctor = () => {
  const importer = {
    url: url => {
      return new Promise((resolve, reject) => {
        let script
        if (typeof window !== "undefined") {
          script = document.createElement("script")
          script.type = "text/javascript"
          script.src = url
          script.addEventListener("load", () => resolve(script), false)
          script.addEventListener("error", () => reject(script), false)
          document.body.appendChild(script)
        }
      })
    },
    urls: urls => {
      return Promise.all(urls.map(importer.url))
    },
  }

  importer
    .urls(["https://code.jquery.com/jquery-3.5.1.min.js", CDN_URL])
    .then(() => {
      PFV.Init("KhUMK49ksE2ZxFXL", CDN_ENV, function () {
        PFV.LoadView("#hcp-embed-container", "index", function () {})
      })
    })

  return <div id="hcp-embed-container"></div>
}

export default FindYourDoctor

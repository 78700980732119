import React, { useContext } from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { Link } from "gatsby"
import { AppContext } from "./context"
import { buttonFocus, purpleButton } from "@styles/ui"

const ButtonIH = ({
  children,
  isExternal, // opens in a new tab and opens exit modal
  newTab, // opens in a new tab
  link,
  useAnchorTag,
  width,
  stackedSpacing,
}) => {
  const styles = [
    purpleButton,
    tw`w-[345px] hover:bg-merlin`,
    buttonFocus,
    width && width,
    stackedSpacing && tw`mt-4 lg:mt-0`,
  ]

  const context = useContext(AppContext)

  const handleClick = e => {
    if (isExternal) {
      e.preventDefault()
      context.setExitModal(true)
      context.setModalLink(link)
    }
  }

  return newTab ? (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      data-external={isExternal}
      css={styles}
      onClick={e => handleClick(e)}
    >
      {children}
    </a>
  ) : useAnchorTag ? (
    <a href={link} rel="noopener noreferrer" css={styles}>
      {children}
    </a>
  ) : (
    <Link to={link} css={styles}>
      {children}
    </Link>
  )
}

export default ButtonIH

ButtonIH.propTypes = {
  isExternal: PropTypes.bool,
  newTab: PropTypes.bool,
  link: PropTypes.string.isRequired,
  useAnchorTag: PropTypes.bool,
  stackedSpacing: PropTypes.bool,
}

ButtonIH.defaultProps = {
  isExternal: false,
  newTab: false,
  useAnchorTag: false,
  stackedSpacing: false,
}

import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import Section from "@components/section"
import Anchor from "@components/anchor"
import BorderedContent from "@components/borderedContent"
import References from "@components/references"
import FindYourDoctor from "@components/findYourDoctor"
import Crosslinks from "@components/crosslinks"
import BorderedCard from "@components/borderedCard"
import { Heading1, Heading2, purpleButton } from "@styles/ui"

// Assets
import logo from "@images/logos/logo-colourful-outline.svg"
import fiveDollarIcon from "@images/icons/financial-assistance/yellow/five-dollar.svg"
import voucherIcon from "@images/icons/financial-assistance/yellow/voucher.svg"
import bridgeIcon from "@images/icons/financial-assistance/yellow/bridge.svg"
import heartIcon from "@images/icons/financial-assistance/yellow/heart.svg"

const CostCoverage = ({ location }) => {
  const keyPoints = [
    {
      image: fiveDollarIcon,
      heading: <span tw="text-transform[none]">The XYWAV Coupon Program</span>,
      text: (
        <p>
          Did you know that &gt;99% of all commercial patients{" "}
          <strong>
            can pay as little as $5 for XYWAV?<sup>a,b</sup>
          </strong>
        </p>
      ),
    },
    {
      image: voucherIcon,
      heading: (
        <span tw="text-transform[none]">The XYWAV Quick Start Voucher</span>
      ),
      text: (
        <p>
          Waiting for your patients&apos; coverage? Your patients{" "}
          <strong>
            could receive a free 1-month supply of XYWAV with the Quick Start
            Voucher.<sup>a</sup>
          </strong>
        </p>
      ),
    },
    {
      image: bridgeIcon,
      heading: <span tw="text-transform[none]">The XYWAV Bridge Program</span>,
      text: (
        <p>
          Your patient{" "}
          <strong>
            may be eligible for up to 120 days of free XYWAV while waiting for
            coverage approval.<sup>a</sup>
          </strong>
        </p>
      ),
    },
    {
      image: heartIcon,
      heading: (
        <span tw="text-transform[none]">
          The XYWAV Patient Assistance Program
        </span>
      ),
      text: (
        <>
          <p tw="mb-1">
            The JazzCares Patient Assistance Program{" "}
            <strong>
              may be able to help when insurance coverage is an issue.
            </strong>
          </p>
          <p>
            Our Patient Assistance Program has helped hundreds of eligible
            patients get access to free medication.<sup>c,d</sup>
          </p>
        </>
      ),
    },
  ]

  const crosslinksCtas = {
    heading: <>Get helpful resources for your patients</>,
    links: [
      {
        text: (
          <>
            PATIENT FINANCIAL <br tw="inline lg:hidden" /> ASSISTANCE & SUPPORT
          </>
        ),
        url: "/jazzcares-for-xywav/",
      },
      {
        text: (
          <>
            PATIENT SUPPORT WITH <br tw="inline lg:hidden" /> MYWAV.COM
          </>
        ),
        url: "https://www.mywav.com/",
        isExternal: true,
        newTab: true,
      },
    ],
  }
  const referenceData = [
    <>Data on File (XYW-2022-024). Palo Alto, CA: Jazz Pharmaceuticals, Inc.</>,
  ]

  return (
    <Layout
      location={location}
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200207 Rev0824
        </>
      }
    >
      <Seo
        title="XYWAV Cost & Insurance Coverage Information | XYWAV HCP"
        description="For prescribers, find cost and coverage information for patients with commercial insurance taking XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <Section
        addedStyles={tw`relative overflow-hidden cost-and-coverage-hero-gradient pb-[135px] pt-[43px] rounded-bl-[100px] md:(pt-10 pb-[100px] ) xl:(pt-[114px] rounded-bl-[150px])`}
      >
        <img
          src={logo}
          tw="absolute right-[-20px] bottom-[-70px] w-[250px] h-[220px] md:(w-[920px] h-[594px] bottom-[-265px] right-[-300px])  xl:(right-[-100px] bottom-[-200px])"
          alt=""
        />
        <Container>
          <p tw="mb-2.5 uppercase font-semibold text-xs text-merlin tracking-wider xl:(text-base)">
            Support &amp; Resources
          </p>
          <h1
            css={[
              Heading1,
              tw`w-full max-w-[500px] md:max-w-[444px] xl:(w-[unset] max-w-[700px] text-[45px])`,
            ]}
          >
            <strong>Cost & coverage</strong>
          </h1>
          <p tw="pt-3 leading-6 md:(max-w-[444px] pr-[32px]) xl:(max-w-[500px] leading-7)">
            <strong>
              Over 90% of commercial patients covered nationwide.
              <sup>1</sup>
            </strong>{" "}
            Patients who have insurance coverage for XYWAV may pay as little as
            $5 per prescription. Financial assistance options are available
            through{" "}
            <Anchor
              link="https://jazzcares.com/"
              underline
              newTab
              isExternal={true}
            >
              JazzCares.com
            </Anchor>
          </p>
        </Container>
      </Section>
      <Section type="primary" addedStyles={tw`pb-0`}>
        <Container>
          <h2
            id="lookup"
            css={[Heading2, tw`uppercase text-base xl:(text-[24px])`]}
          >
            View coverage information relevant to your practice
          </h2>
          <p tw="my-2.5 xl:(mt-5 mb-8)">
            Enter your ZIP Code or National Provider Identifier (NPI) number to
            view local coverage information for XYWAV
          </p>
          <FindYourDoctor />
        </Container>
      </Section>

      <Section addedStyles={tw`bg-twilight-blue xl:(pt-[65px] pb-10)`}>
        <Container
          addedStyles={tw`bg-white px-5 pt-14 pb-8 rounded-tl-[50px] rounded-br-[50px] xl:(max-w-[1320px] px-[74px] pb-[32px] rounded-tl-[130px] rounded-br-[130px])`}
        >
          <h2 tw="font-semibold text-4xl mb-4">
            XYWAV financial assistance programs through JazzCares
            <sup>&reg;</sup>
          </h2>
          <h3 tw="text-[18px] mb-8">
            JazzCares may help find a financial assistance program that fits the
            needs of your patients
          </h3>
          <div tw="flex flex-col gap-[28px] xl:(flex-row flex-wrap justify-center items-stretch)">
            {keyPoints.map((card, index) => (
              <BorderedCard
                key={`financial-assistance-${index}`}
                cardData={card}
                addedStyles={tw`w-full rounded-[70px] background-image[linear-gradient(29.42deg, #f93549 7.67%, #ad95dc 51.86%, #ffbf3c 87.52%)] xl:(w-[calc(50% - 14px)] py-[26px] px-[46px] h-auto min-h-[339px] flex flex-col items-center justify-start) before:(content[''] absolute left-0 right-0 top-0 bottom-0 m-[4px] bg-white rounded-[67px] xl:rounded-[57px])`}
              />
            ))}
          </div>

          <div tw="flex items-center justify-center my-10 xl:my-[70px]">
            <Anchor
              link="https://jazzcares.com/patients/xywav/"
              isExternal
              newTab
              addedStyles={[
                purpleButton,
                tw`mx-auto px-5 py-4 inline-block xl:(px-10 py-5)`,
              ]}
            >
              Get Started With Jazzcares
            </Anchor>
          </div>

          <div tw="max-w-[975px] mx-auto text-[12px]">
            <ul>
              <li tw="text-indent[-5px] pl-[5px]">
                <sup>a</sup>Applies only to eligible, commercially insured
                patients enrolled in the XYWAV and XYREM&nbsp;REMS.
              </li>
              <li tw="text-indent[-7px] pl-[7px]">
                <sup>b</sup>Eligible patients pay as little as $5. Subject to
                annual maximum&nbsp;benefit.
              </li>
              <li tw="text-indent[-5px] pl-[5px]">
                <sup>c</sup>Subject to financial and other eligibility criteria.
                Must be enrolled in the XYWAV and XYREM&nbsp;REMS.
              </li>
              <li tw="text-indent[-7px] pl-[7px]">
                <sup>d</sup>The Patient Assistance Program application is
                available online at{" "}
                <Anchor
                  link="https://www.jazzcares.com/xywav"
                  isExternal
                  newTab
                  underline
                >
                  www.jazzcares.com/xywav
                </Anchor>
              </li>
            </ul>
            <p tw="mt-6">
              Jazz Pharmaceuticals reserves the right to terminate or modify at
              any time with or without notice. Other{" "}
              <Anchor
                link="https://jazzcares.com/hcp/xywav/#termsandconditions"
                isExternal
                newTab
                underline
              >
                Terms and Conditions
              </Anchor>
              &nbsp;apply.
            </p>
          </div>
        </Container>
      </Section>

      <Section type="primary">
        <Container>
          <BorderedContent
            extraCurved
            thinBorder
            addedStyles={tw`bg-transparent py-10 px-[20px]`}
          >
            <h2 css={[Heading2, tw`mb-[30px] text-center xl:(mb-2.5)`]}>
              Have additional questions?
            </h2>
            <p tw="text-center xl:(max-w-[960px] mx-auto)">
              Contact your Jazz Pharmaceuticals Access and Reimbursement
              Manager, visit{" "}
              <Anchor link="https://jazzcares.com/" isExternal newTab underline>
                JazzCares.com
              </Anchor>
              , or call{" "}
              <a href="tel:18669973688" tw="underline">
                <nobr>1-866-997-3688</nobr>
              </a>
              , <nobr>Monday – Friday, 7 AM – 8 PM CT.</nobr>
            </p>
          </BorderedContent>
        </Container>
      </Section>
      <Section addedStyles={tw`pt-0 pb-0`}>
        <Container>
          <Crosslinks ctas={crosslinksCtas} narc />
        </Container>
      </Section>
      <References referenceData={referenceData} paddingPrimary />
    </Layout>
  )
}

export default CostCoverage

CostCoverage.propTypes = {
  location: PropTypes.object.isRequired,
}
